import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

let routes: RouteRecordRaw[]

if (window.innerWidth > 1200) {
    routes = [
        {
            path: '/',
            redirect: '/auth/login',
            component: () => import('@/views/Auth/AuthPage.vue'),
            children: [
                {
                    path: '/auth/login',
                    name: 'auth',
                    component: () => import('@/views/Auth/components/SignIn.vue')
                },
                {
                    path: '/auth/new-password',
                    component: () => import('@/views/Auth/NewPasswordPage.vue'),
                },
                {
                    path: '/auth/new-password-success',
                    component: () => import('@/views/Auth/NewPasswordSuccessPage.vue'),
                },
                {
                    path: '/auth/clinic-choice',
                    name: 'choice-clinic',
                    component: () => import('@/views/Auth/components/ClinicChoice.vue')
                },
                {
                    path: '/auth/restore-password',
                    component: () => import('@/views/Auth/components/RestorePassword.vue'),
                },
                {
                    path: '/auth/restore-password-success',
                    component: () =>
                        import('@/views/Auth/RestorePasswordSuccessPage.vue'),
                },
            ],
        },
        {
            path: '/',
            redirect: '/lk/home',
            name: 'home',
            children: [
                {
                    path: '/lk',
                    component: () => import('@/views/MainRoot/MainRoot.vue'),
                    children: [
                        {
                            path: '/lk/home/patient/patient-null/periodontal-map/create',
                            name: 'PeriodontalMapNull',
                            component: () =>
                                import(
                                    '@/views/MainRoot/PeriodontalPage/PeriodontalPage.vue'
                                    ),
                        },
                        {
                            path: '/lk/home/patient/:patient_id/periodontal-map/create',
                            name: 'PeriodontalMapCreate',
                            component: () =>
                                import(
                                    '@/views/MainRoot/PeriodontalPage/PeriodontalPage.vue'
                                    ),
                        },
                        {
                            path: '/lk/home/patient/:patient_id/periodontal-map',
                            name: 'PeriodontalMapView',
                            component: () =>
                                import(
                                    '@/views/MainRoot/PeriodontalPage/PeriodontalPage.vue'
                                    ),
                        },
                        {
                            path: '/lk/home',
                            name: 'home',
                            component: () => import('@/views/MainRoot/HomePage/HomePage.vue'),
                            children: [
                                {
                                    path: '/lk/home/patient/patient-null/express-periodontal/create',
                                    name: 'ExpressPeriodontalNull',
                                    component: () =>
                                        import(
                                            '@/views/MainRoot/Patient/ExpressPeriodontal/ExpressPeriodontal.vue'
                                            ),
                                },
                                {
                                    path: '/lk/home/patient/patient-null/plaque-index/create',
                                    name: 'PlaqueIndexNull',
                                    component: () =>
                                        import(
                                            '@/views/MainRoot/Patient/PlaqueIndexCreating/PlaqueIndexCreating.vue'
                                            ),
                                },
                                {
                                    path: '/lk/home/patient/patient-null/brush-map/create',
                                    name: 'BrushMapNull',
                                    component: () =>
                                        import(
                                            '@/views/MainRoot/Patient/RasherMap/BrushMap.vue'
                                            ),
                                },
                                {
                                    path: '/lk/home/patient/:patient_id',
                                    name: 'PatientPage',
                                    component: () =>
                                        import(
                                            '@/views/MainRoot/Patient/PatientPage/PatientPage.vue'
                                            ),
                                    props: true,
                                    children: [
                                        {
                                            path: '/lk/home/patient/:patient_id/brush-map/create',
                                            name: 'BrushMap',
                                            component: () =>
                                                import(
                                                    '@/views/MainRoot/Patient/RasherMap/BrushMap.vue'
                                                    ),
                                            props: true,
                                        },
                                        {
                                            path: '/lk/home/patient/:patient_id/editing',
                                            name: 'PatientEditing',
                                            component: () =>
                                                import(
                                                    '@/views/MainRoot/Patient/PatientEditing/PatientEditing.vue'
                                                    ),
                                            props: true,
                                        },
                                        {
                                            path: '/lk/home/patient/:patient_id/plaque-index',
                                            name: 'PlaqueIndexReport',
                                            component: () =>
                                                import(
                                                    '@/views/MainRoot/Patient/PlaqueIndex/PlaqueIndex.vue'
                                                    ),
                                            props: true,
                                        },
                                        {
                                            path: '/lk/home/patient/:patient_id/express-periodontal',
                                            name: 'ExpressPeriodontalReport',
                                            component: () =>
                                                import(
                                                    '@/views/MainRoot/Patient/ExpressPeriodontal/ExpressPeriodontal.vue'
                                                    ),
                                            props: true,
                                        },
                                        {
                                            path: '/lk/home/patient/:patient_id/risk-map',
                                            name: 'RiskMapReport',
                                            component: () =>
                                                import('@/views/MainRoot/Patient/RiskMap/RiskMap.vue'),
                                            props: true,
                                        },
                                        {
                                            path: '/lk/home/patient/:patient_id/risk-map/create',
                                            name: 'RiskMapCreate',
                                            component: () =>
                                                import('@/views/MainRoot/Patient/RiskMap/RiskMap.vue'),
                                            props: true,
                                        },
                                        {
                                            path: '/lk/home/patient/:patient_id/express-periodontal/create',
                                            name: 'ExpressPeriodontalCreate',
                                            component: () =>
                                                import(
                                                    '@/views/MainRoot/Patient/ExpressPeriodontal/ExpressPeriodontal.vue'
                                                    ),
                                            props: true,
                                        },
                                        {
                                            path: '/lk/home/patient/:patient_id/plaque-index/create',
                                            name: 'PlaqueIndexCreate',
                                            component: () =>
                                                import(
                                                    '@/views/MainRoot/Patient/PlaqueIndexCreating/PlaqueIndexCreating.vue'
                                                    ),
                                            props: true,
                                        },
                                        {
                                            path: '/lk/home/patient/:patient_id/plaque-index/:report_id',
                                            name: 'PlaqueIndex',
                                            component: () =>
                                                import(
                                                    '@/views/MainRoot/Patient/PlaqueIndex/PlaqueIndex.vue'
                                                    ),
                                            props: true,
                                        },
                                        {
                                            path: '/lk/home/patient/:patient_id/express-periodontal/:report_id',
                                            name: 'ExpressPeriodontal',
                                            component: () =>
                                                import(
                                                    '@/views/MainRoot/Patient/ExpressPeriodontal/ExpressPeriodontal.vue'
                                                    ),
                                            props: true,
                                        },
                                    ],
                                },
                                {
                                    path: '/lk/home/patient/create',
                                    name: 'PatientCreating',
                                    component: () =>
                                        import(
                                            '@/views/MainRoot/Patient/PatientCreating/PatientCreating.vue'
                                            ),
                                },
                            ],
                        },
                        {
                            path: '/lk/indicators',
                            name: 'indicators',
                            component: () =>
                                import('@/views/MainRoot/IndicatorsPage/IndicatorsPage.vue'),
                        },
                        {
                            path: '/lk/statistic',
                            name: 'statistic',
                            component: () =>
                                import('@/views/MainRoot/StatisticPage/StatisticPage.vue'),
                        },
                        {
                            path: '/lk/archive',
                            name: 'archive',
                            component: () =>
                                import('@/views/MainRoot/ArchiveHomePage/ArchiveHomePage.vue'),
                            children: [
                                {
                                    path: '/lk/archive/:clinic_id',
                                    name: 'UserPage',
                                    component: () =>
                                        import('@/views/MainRoot/User/UserPage/UserPage.vue'),
                                    props: true,
                                }
                            ]
                        },
                        {
                            path: '/lk/users',
                            name: 'users',
                            component: () =>
                                import('@/views/MainRoot/UserHomePage/UserHomePage.vue'),
                            children: [
                                {
                                    path: '/lk/users/:user_id',
                                    name: 'UserPage',
                                    component: () =>
                                        import('@/views/MainRoot/User/UserPage/UserPage.vue'),
                                    props: true,
                                    children: [
                                        {
                                            path: '/lk/users/:user_id/edit',
                                            name: 'UserEditing',
                                            component: () =>
                                                import('@/views/MainRoot/User/UserEditing/UserEditing.vue'),
                                        },
                                        {
                                            path: '/lk/users/:user_id/statistic',
                                            name: 'UserStatistic',
                                            props: true,
                                            component: () =>
                                                import('@/views/MainRoot/User/UserStatistic/UserStatistic.vue'),
                                        },
                                    ]
                                },
                                {
                                    path: '/lk/users/create',
                                    name: 'UserCreating',
                                    component: () =>
                                        import(
                                            '@/views/MainRoot/User/UserCreating/UserCreating.vue'
                                            ),
                                },
                            ],
                        },
                        {
                            path: '/lk/clinics',
                            name: 'clinics',
                            component: () =>
                                import('@/views/MainRoot/ClinicHomePage/ClinicHomePage.vue'),
                            children: [
                                {
                                    path: '/lk/clinics/:clinic_id',
                                    name: 'ClinicPage',
                                    component: () =>
                                        import('@/views/MainRoot/Clinic/ClinicPage/ClinicPage.vue'),
                                    children: [
                                        {
                                            path: '/lk/clinics/:clinic_id/edit',
                                            name: 'ClinicEditing',
                                            component: () =>
                                                import(
                                                    '@/views/MainRoot/Clinic/ClinicEditing/ClinicEditing.vue'
                                                    ),
                                        },
                                        {
                                            path: '/lk/clinics/:clinic_id/statistic',
                                            name: 'ClinicStatistic',
                                            props: true,
                                            component: () =>
                                                import(
                                                    '@/views/MainRoot/Clinic/ClinicReportStatistics/ClinicReportsStatistics.vue'
                                                    ),
                                        },
                                        {
                                            path: '/lk/clinics/:clinic_id/statistic/plaque-index',
                                            name: 'PlaqueIndexStatistic',
                                            props: true,
                                            component: () =>
                                                import(
                                                    '@/views/MainRoot/Clinic/ClinicStatisticPage/PlaqueIndexStatistic/PlaqueIndexStatistic.vue'
                                                    ),
                                        },
                                        {
                                            path: '/lk/clinics/:clinic_id/statistic/periodontal',
                                            name: 'PeriodontalStatistic',
                                            props: true,
                                            component: () =>
                                                import(
                                                    '@/views/MainRoot/Clinic/ClinicStatisticPage/PeriodontalStatistic/PeriodontalStatistic.vue'
                                                    ),
                                        },
                                    ]
                                },
                                {
                                    path: '/lk/clinics/create',
                                    name: 'ClinicCreating',
                                    component: () =>
                                        import(
                                            '@/views/MainRoot/Clinic/ClinicCreating/ClinicCreatingPage.vue'
                                            ),
                                },
                            ],
                        },
                    ],
                },
                {
                    path: '/:pathMatch(.*)*',
                    name: '404',
                    component: () => import('@/views/Page404/Page404.vue'),
                },
            ],
        },
    ]
} else {
    routes = [
        {
            path: '/auth',
            redirect: '/auth/login',
            component: () => import('@/views/Auth/AuthPage.vue'),
            children: [
                {
                    path: '/auth/login',
                    component: () => import('@/views/Auth/components/SignIn.vue')
                },
                {
                    path: '/auth/new-password',
                    component: () => import('@/views/Auth/NewPasswordPage.vue'),
                },
                {
                    path: '/auth/new-password-success',
                    component: () => import('@/views/Auth/NewPasswordSuccessPage.vue'),
                },
                {
                    path: '/auth/clinic-choice',
                    component: () => import('@/views/Auth/components/ClinicChoice.vue')
                },
                {
                    path: '/auth/restore-password',
                    component: () => import('@/views/Auth/components/RestorePassword.vue'),
                },
                {
                    path: '/auth/restore-password-success',
                    component: () =>
                        import('@/views/Auth/RestorePasswordSuccessPage.vue'),
                },
            ],
        },
        {
            path: '/',
            redirect: {path: '/lk/home'},
        },
        {
            path: '/lk',
            name: 'root',
            component: () => import('@/views/MainRoot/MainRoot.vue'),
            children: [
                {
                    path: '/lk/home',
                    name: 'home',
                    component: () => import('@/views/MainRoot/HomePage/HomePage.vue'),
                },
                {
                    path: '/lk/archive',
                    name: 'ArchivePage',
                    component: () =>
                        import('@/views/MainRoot/ArchivePage/ArchivePage.vue'),
                },
                {
                    path: '/lk/indicators',
                    name: 'indicators',
                    component: () =>
                        import('@/views/MainRoot/IndicatorsPage/IndicatorsPage.vue'),
                },
                {
                    path: '/lk/statistic',
                    name: 'statistic',
                    component: () =>
                        import('@/views/MainRoot/StatisticPage/StatisticPage.vue'),
                },
                {
                    path: '/lk/home/patient/:patient_id',
                    name: 'PatientPage',
                    component: () =>
                        import('@/views/MainRoot/Patient/PatientPage/PatientPage.vue'),
                    props: true,
                },
                {
                    path: '/lk/home/patient/:patient_id/editing',
                    name: 'PatientEditing',
                    component: () =>
                        import(
                            '@/views/MainRoot/Patient/PatientEditing/PatientEditing.vue'
                            ),
                    props: true,
                },
                {
                    path: '/lk/home/patient/:patient_id/plaque-index',
                    name: 'PlaqueIndexReport',
                    component: () =>
                        import('@/views/MainRoot/Patient/PlaqueIndex/PlaqueIndex.vue'),
                    props: true,
                },
                {
                    path: '/lk/home/patient/:patient_id/express-periodontal',
                    name: 'ExpressPeriodontalReport',
                    component: () =>
                        import(
                            '@/views/MainRoot/Patient/ExpressPeriodontal/ExpressPeriodontal.vue'
                            ),
                    props: true,
                },
                {
                    path: '/lk/home/patient/:patient_id/risk-map',
                    name: 'RiskMapReport',
                    component: () =>
                        import('@/views/MainRoot/Patient/RiskMap/RiskMap.vue'),
                    props: true,
                },
                {
                    path: '/lk/home/patient/:patient_id/risk-map/create',
                    name: 'RiskMapCreate',
                    component: () =>
                        import('@/views/MainRoot/Patient/RiskMap/RiskMap.vue'),
                    props: true,
                },
                {
                    path: '/lk/home/patient/:patient_id/express-periodontal/create',
                    name: 'ExpressPeriodontalCreate',
                    component: () =>
                        import(
                            '@/views/MainRoot/Patient/ExpressPeriodontal/ExpressPeriodontal.vue'
                            ),
                    props: true,
                },
                {
                    path: '/lk/home/patient/:patient_id/plaque-index/create',
                    name: 'PlaqueIndexCreate',
                    component: () =>
                        import(
                            '@/views/MainRoot/Patient/PlaqueIndexCreating/PlaqueIndexCreating.vue'
                            ),
                    props: true,
                },
                {
                    path: '/lk/home/patient/create',
                    name: 'PatientCreatdading',
                    component: () =>
                        import(
                            '@/views/MainRoot/Patient/PatientCreating/PatientCreating.vue'
                            ),
                },
                {
                    path: '/lk/home/patient/patient-null/risk-map/create',
                    name: 'RiskMapCreating',
                    component: () =>
                        import('@/views/MainRoot/Patient/RiskMap/RiskMap.vue'),
                    props: true,
                },
                {
                    path: '/lk/home/patient/patient-null/express-periodontal/create',
                    name: 'ExpressPeriodontalCreating',
                    component: () =>
                        import(
                            '@/views/MainRoot/Patient/ExpressPeriodontal/ExpressPeriodontal.vue'
                            ),
                    props: true,
                },
                {
                    path: '/lk/home/patient/patient-null/plaque-index/create',
                    name: 'PlaqueIndexCreating',
                    component: () =>
                        import(
                            '@/views/MainRoot/Patient/PlaqueIndexCreating/PlaqueIndexCreating.vue'
                            ),
                    props: true,
                },
                {
                    path: '/lk/home/patient/:patient_id/plaque-index/:report_id',
                    name: 'PlaqueIndex',
                    component: () =>
                        import('@/views/MainRoot/Patient/PlaqueIndex/PlaqueIndex.vue'),
                    props: true,
                },
                {
                    path: '/lk/home/patient/:patient_id/express-periodontal/:report_id',
                    name: 'ExpressPeriodontal',
                    component: () =>
                        import(
                            '@/views/MainRoot/Patient/ExpressPeriodontal/ExpressPeriodontal.vue'
                            ),
                    props: true,
                },
                {
                    path: '/lk/home/patient/:patient_id/risk-map/:report_id',
                    name: 'RiskMap',
                    component: () =>
                        import('@/views/MainRoot/Patient/RiskMap/RiskMap.vue'),
                    props: true,
                },
                {
                    path: '/lk/clinics/create',
                    name: 'ClinicCreating',
                    component: () =>
                        import(
                            '@/views/MainRoot/Clinic/ClinicCreating/ClinicCreatingPage.vue'
                            ),
                },
                {
                    path: '/lk/clinics/:clinic_id/edit',
                    name: 'ClinicEditing',
                    component: () =>
                        import(
                            '@/views/MainRoot/Clinic/ClinicEditing/ClinicEditing.vue'
                            ),
                },
                {
                    path: '/lk/users/create',
                    name: 'UserCreating',
                    component: () =>
                        import('@/views/MainRoot/User/UserCreating/UserCreating.vue'),
                },
                {
                    path: '/lk/clinics',
                    name: 'clinics',
                    component: () =>
                        import('@/views/MainRoot/Clinic/ClinicList/ClinicListPage.vue'),
                },
                {
                    path: '/lk/users',
                    name: 'users',
                    component: () =>
                        import('@/views/MainRoot/User/UserListPage/UserListPage.vue'),
                },
                {
                    path: '/lk/users/:user_id',
                    name: 'UserPage',
                    component: () =>
                        import('@/views/MainRoot/User/UserPage/UserPage.vue'),
                },
                {
                    path: '/lk/users/:user_id/edit',
                    name: 'UserEditing',
                    component: () =>
                        import('@/views/MainRoot/User/UserEditing/UserEditing.vue'),
                },
                {
                    path: '/lk/users/:user_id/statistic',
                    name: 'UserStatistic',
                    component: () =>
                        import('@/views/MainRoot/User/UserStatistic/UserStatistic.vue'),
                },
                {
                    path: '/lk/clinics/:clinic_id',
                    name: 'ClinicPage',
                    component: () =>
                        import('@/views/MainRoot/Clinic/ClinicPage/ClinicPage.vue'),
                },
                {
                    path: '/lk/clinics/:clinic_id/statistic',
                    name: 'ClinicStatisticReports',
                    props: true,
                    component: () =>
                        import(
                            '@/views/MainRoot/Clinic/ClinicReportStatistics/ClinicReportsStatistics.vue'
                            ),
                },
            ],
        },
        {
            path: '/:pathMatch(.*)*',
            name: '404',
            component: () => import('@/views/Page404/Page404.vue'),
        },
    ]
}

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes,
})

router.beforeEach(async (to, from) => {
    if (!localStorage.getItem('accessToken') && to.name !== 'auth') return {name: 'auth'}
    if (localStorage.getItem('accessToken') && !localStorage.getItem('clinicID') && to.name !== 'choice') return {name: 'choice-clinic'}
    if (localStorage.getItem('accessToken') && localStorage.getItem('clinicID')) return
})

export default router
