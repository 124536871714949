import {createApp, DirectiveBinding} from 'vue'
import App from './App.vue'
import router from "@/router";
import {defaultLocale, languages} from "@/i18n";
import {createI18n} from "vue-i18n"
import {createPinia} from "pinia";
import {useTokenStore} from "@/store/TokenStore";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "@/styles/element-plus/common.scss"

const messages = Object.assign(languages)
const i18n = createI18n({
    legacy: false,
    fallbackLocale: "ru",
    locale: defaultLocale,
    messages
})

const vClickOutside = {
    mounted(el: ClickableEl, binding: DirectiveBinding) {
        el.clickEvent = function (e: Event) {
            // Checking the click was not on the element nor it's children
            if (!(el == e.target || el.contains(e.target as Node))) {
                // call method provided in attribute value
                binding.value()
            }
        };
        document.addEventListener("click", el.clickEvent);
    },
    unmounted(el: ClickableEl) {
        document.removeEventListener("click", el.clickEvent);
    },
};

interface ClickableEl extends Element {
    clickEvent: (e: Event) => void;
}

createApp(App).use(ElementPlus).directive('click-outside', vClickOutside).use(createPinia()).use(i18n).use(router).mount('#app')

const tokenStore = useTokenStore()
tokenStore.$subscribe((mutation, state) => {
    localStorage.setItem('accessToken', state.accessToken)
    localStorage.setItem('refreshToken', state.refreshToken)
    localStorage.setItem('clinicID', state.clinicID)
    localStorage.setItem('organizationID', state.organizationID)
    localStorage.setItem('isAdmin', String(state.isAdmin))
    localStorage.setItem('isHeadDoctor', String(state.isHeadDoctor))
})