import {defineStore} from "pinia"

export const useTokenStore = defineStore('token', {
    state: () => ({
        accessToken: '',
        refreshToken: '',
        clinicID: '',
        organizationID: '',
        isAdmin: false,
        isHeadDoctor: false
    }),
    actions: {
        initTokens() {
            let accessToken = localStorage.getItem('accessToken')
            if (accessToken !== null) {
                this.accessToken = accessToken
            }
            let refreshToken = localStorage.getItem('refreshToken')
            if (refreshToken !== null) {
                this.refreshToken = refreshToken
            }
            let clinicID = localStorage.getItem('clinicID')
            if (clinicID !== null) {
                this.clinicID = clinicID
            }
            let organizationID = localStorage.getItem('organizationID')
            if (organizationID !== null) {
                this.organizationID = organizationID
            }
            let isAdmin = localStorage.getItem('isAdmin')
            if (isAdmin !== null) {
                this.isAdmin = isAdmin === 'true'
            }
            let isHeadDoctor = localStorage.getItem('isHeadDoctor')
            if (isHeadDoctor !== null) {
                this.isHeadDoctor = isHeadDoctor === 'true'
            }
        },
        logout() {
            this.accessToken = ''
            this.refreshToken = ''
            this.clinicID = ''
            this.organizationID = ''
            this.isAdmin = false
            this.isHeadDoctor = false
        }
    }
})

