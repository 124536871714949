<script>
import {defineComponent} from 'vue'
import {useTokenStore} from '@/store/TokenStore'

export default defineComponent({
  setup() {
    const tokenStore = useTokenStore()
    tokenStore.initTokens()
  },
})
</script>

<template>
  <router-view/>
</template>

<style lang="scss">
@import '@/styles/index';

#app {
  overflow: hidden;
  width: 100%;
  height: 100%;
  font-family: sans-serif;
}
</style>
